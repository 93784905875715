<template>
  <div v-if="!isAvailable" class="alert alert-warning border-warning text-center">
    <button :disabled="!permSetting" class="btn btn-link p-0" @click="activateNormalRate">เปิดใช้อัตราจ่ายปกติ</button>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundNormalRate',
  props: ['data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    isAvailable() {
      return this.data?.useRate?.rateType === 'normal'
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.Payrate === 'w'
    }
  },
  methods: {
    activateNormalRate() {
      Swal.fire({
        text: 'เปิดใช้อัตราจ่ายปกติ!',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          RoundService.activateRateType(this.data._id, {rateType: 'normal'}).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'เปิดใช้อัตราจ่ายปกติแล้ว'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              title: 'ผิดพลาด!',
              text: e?.message || 'เปิดใช้อัตราจ่ายปกติไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.$emit('reload')
          })
        }
      })
    }
  }
}
</script>
