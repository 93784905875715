<template>
  <div>
    <table class="table table-bordered table-warning table-hover">
      <thead>
        <tr>
          <th :width="100/marketOpenBets.length" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
        </tr>
        <tr v-if="isAvailable">
          <td class="p-1" v-for="bet in marketOpenBets" :key="bet.code">
            <div class="d-flex justify-content-between">
              <small>ยอดรับ(>)</small>
              <small>ลดเรทจ่าย</small>
            </div>
          </td>
        </tr>
      </thead>
      <tbody class="bg-light text-dark" v-if="isAvailable">
        <tr v-for="data in stepDatas">
          <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center p-2">
            <div class="d-flex justify-content-between">
              <small class="text-info" v-if="data[bet.code].minReceive !== ''">{{ data[bet.code].minReceive | amountFormat(0, '0') }}</small>
              <small class="text-danger">{{ data[bet.code].cutRate }}</small>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="alert-warning bg-light">
          <td :colspan="marketOpenBets.length" class="text-center">
            <button :disabled="!permSetting" class="btn btn-link p-0" @click="activateStepRate">เปิดใช้อัตราจ่ายแบบลดขั้น</button>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="isAvailable">
        <tr>
          <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center p-2">
            <button :disabled="!permSetting" class="btn btn-sm btn-link p-0" @click="setStepRate(bet)"><i class="fas fa-cog"></i> ตั้งค่า</button>
          </td>
        </tr>
      </tfoot>
    </table>

    <StepRateModal :is-show="isShowModal" :round="round" :bet-key="betKey" @close="modalClosed" />
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import Swal from 'sweetalert2'
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'
import StepRateModal from './StepRateModal'
import _ from 'lodash'

export default {
  name: 'RoundStepRate',
  components: {
    StepRateModal
  },
  props: ['market', 'round'],
  data() {
    return {
      isShowModal: false,
      betKey: {
        code: '',
        text: ''
      }
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets.filter((m)=>{
        return this.market?.openBets?.[m.code]
      })
    },
    isAvailable() {
      return this.round?.useRate?.rateType === 'steprate'
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.Payrate === 'w'
    },
    stepDatas() {
      const stepDataRows = this.marketOpenBets.map((open)=>{
        return this.round?.useRate?.steprateData?.[open.code]?.length ?? 0
      })

      const maxRow = _.max(stepDataRows)
      const rows = []
      for (let i = 0; i < maxRow; i++) {
        const row = this.marketOpenBets.reduce((cols, open)=>{
          const keyData = _.sortBy(this.round?.useRate?.steprateData?.[open.code] ?? [], ['minReceive'])
          const minReceive = keyData?.[i]?.minReceive ?? ''
          const cutRate = keyData?.[i]?.cutRate ?? ''
          cols[open.code] = { minReceive, cutRate }
          return cols
        }, {})
        rows.push(row)
      }

      return rows
    }
  },
  methods: {
    setStepRate(bet) {
      this.betKey = bet
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
    activateStepRate() {
      Swal.fire({
        text: 'เปิดใช้อัตราจ่ายแบบลดขั้น!',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          RoundService.activateRateType(this.round._id, {rateType: 'steprate'}).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'เปิดใช้อัตราจ่ายแบบลดขั้นแล้ว'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              title: 'ผิดพลาด!',
              text: e?.message || 'เปิดใช้อัตราจ่ายแบบลดขั้นไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.$emit('reload')
          })
        }
      })
    }
  }
}
</script>
