<template>
  <b-modal
    id="fixrate-modal"
    ref="round-fixrate-modal"
    hide-footer
    size="md"
    title="ตั้งค่าอัตราจ่ายแบบลดขั้น"
    @show="setData"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="หวย"
        label-class="mb-0 pb-0"
      >
        <span class="text-primary">{{ roundName }}</span>
      </b-form-group>

      <b-form-group
        label="งวดวันที่"
        label-class="mb-0 pb-0"
      >
        <span class="text-primary">{{ roundDate }}</span>
      </b-form-group>

      <div class="mt-2">
        <table class="table table-sm mb-0">
          <thead>
            <tr>
              <th colspan="3" class="text-center text-primary border-0">{{ betText }}</th>
            </tr>
            <tr>
              <td width="50%" class="text-center">ยอดรับ (มากกว่า)</td>
              <td width="50%" class="text-center">ลดเรทจ่าย</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(input, index) in inputs">
              <td class="pl-0">
                <b-form-input
                  v-model="input.minReceive"
                  size="sm"
                  :number="true"
                  required
                  v-on:keydown="keypress"
                />
              </td>
              <td>
                <b-form-input
                  v-model="input.cutRate"
                  size="sm"
                  :number="true"
                  required
                  v-on:keydown="keypress"
                />
              </td>
              <td class="px-0 text-center">
                <button type="button" class="btn btn-outline-danger btn-sm" @click="removeRow(index)"><i class="far fa-trash-alt"></i></button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3" class="py-1 pr-0 text-right">
                <button type="button" class="btn btn-outline-info btn-sm mr-1 px-3 py-0" @click="sortData"><i class="fas fa-sort-numeric-down"></i></button>
                <button type="button" class="btn btn-outline-success btn-sm px-3 py-0" @click="addRow"><i class="fas fa-plus"></i></button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-form-checkbox
            v-model="saveToMarket"
            :value="true"
            :unchecked-value="false"
          >
            บันทึกใช้กับงวดถัดไป
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
          <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import moment from '@/helpers/moment'
import _ from 'lodash'
import RoundService from '@/services/RoundService'
import cAlert from '@/helpers/alert'

export default {
  name: 'StepRateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    round: {
      type: Object
    },
    betKey: {
      type: Object,
      default: ()=>{
        return {
          code: '',
          text: ''
        }
      }
    }
  },
  data() {
    return {
      isProcess: false,
      saveToMarket: false,
      inputs: [{
        minReceive: 0,
        cutRate: 0
      }]
    }
  },
  computed: {
    roundName() {
      if(!this.round)
        return ''

      return `${this.round.note.groupTitle} / ${this.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD/MM/YYYY")
    },
    betText() {
      return `ตั้งค่า ${this.betKey.text}`
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['round-fixrate-modal'].show()
    },
    hideModal() {
      this.$refs['round-fixrate-modal'].hide()
    },
    setData() {
      this.isProcess = false
      this.saveToMarket = false
      const inputs = JSON.parse(JSON.stringify(this.round?.useRate?.steprateData?.[this.betKey.code] ?? [{ minReceive: 0, cutRate: 0 }]))
      this.inputs = _.sortBy(inputs, ['minReceive'])
    },
    addRow() {
      this.inputs = _.sortBy(this.inputs, ['minReceive'])
      this.inputs.push({
        minReceive: null,
        cutRate: null
      })
    },
    removeRow(index) {
      this.inputs.splice(index, 1)
    },
    sortData() {
      this.inputs = _.sortBy(this.inputs, ['minReceive'])
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '.'].includes(event.key)) {
        event.preventDefault()
      }
    },
    handleSubmit() {
      this.isProcess = true
      RoundService.saveStepRate(this.round._id, {
        saveToMarket: this.saveToMarket,
        betCode: this.betKey.code,
        stepData: _.sortBy(this.inputs, ['minReceive'])
      })
      .then((response) => {
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e) => {
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
